<template>
  <input
    ref="mapInput"
    class="controls mapinput"
    type="text"
    placeholder="Search Location"
  />
  <div class="map" ref="mapDivRef"></div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Map",
  setup(props, { emit }) {
    const mapDivRef = ref(null);
    const mapInput = ref(null);
    const map = ref(null);
    let markers = [];

    function placeMarker(location) {
      if (markers.length >= 10) {
        let deleteMarker = markers.shift();
        deleteMarker.setMap(null);
      }
      let marker = new window.google.maps.Marker({
        position: location,
        map: map.value,
      });
      markers.push(marker);
    }
    function calculateCenter() {
      var bound = new window.google.maps.LatLngBounds();
      for (let i = 0; i < markers.length; i++) {
        bound.extend(
          new window.google.maps.LatLng(
            markers[i].position.lat(),
            markers[i].position.lng()
          )
        );
      }
      emit(
        "apply",
        {
          lat: bound.getCenter().lat(),
          lng: bound.getCenter().lng(),
        },
        markers.length
      );
    }
    function CenterControl(controlDiv) {
      // Set CSS for the control border.
      const controlUI = document.createElement("div");
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.marginTop = "8px";
      controlUI.style.marginBottom = "22px";
      controlUI.style.textAlign = "center";
      controlUI.title = "Click to recenter the map";
      controlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlText = document.createElement("div");
      controlText.style.color = "rgb(25,25,25)";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlText.style.fontSize = "16px";
      controlText.style.lineHeight = "38px";
      controlText.style.paddingLeft = "5px";
      controlText.style.paddingRight = "5px";
      controlText.innerHTML = "Remove all Markers";
      controlUI.appendChild(controlText);
      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener("click", () => {
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
        markers = [];
        emit("apply", null, null);
      });
    }
    window.initMap = () => {
      map.value = new window.google.maps.Map(mapDivRef.value, {
        mapTypeId: "hybrid",
        zoom: 9,
        minZoom: 5,
        maxZoom: 19,
        center: {
          lat: 49.6751847,
          lng: 9.6654504,
        },
        styles: [
          {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ],
      });
      window.google.maps.event.addListener(
        map.value,
        "click",
        function (event) {
          placeMarker(event.latLng);
          calculateCenter();
        }
      );
      const searchBox = new window.google.maps.places.SearchBox(mapInput.value);
      map.value.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
        mapInput.value
      );
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        map.value.setCenter(places[0].geometry.location);
      });
      const centerControlDiv = document.createElement("div");
      CenterControl(centerControlDiv, map);
      map.value.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
        centerControlDiv
      );
    };
    const key = "AIzaSyC3jfrveJchQcf2V9kXX4O_BQA1Fkutwpo";

    // create the script element to load
    const googleMapScript = document.createElement("SCRIPT");
    googleMapScript.setAttribute(
      "src",
      `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap&libraries=places`
    );
    googleMapScript.setAttribute("defer", "");
    googleMapScript.setAttribute("async", "");
    document.head.appendChild(googleMapScript);

    return {
      mapDivRef,
      mapInput,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mapinput {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 22px;
  text-align: left;
  color: rgb(25, 25, 25);
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 32px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
