<template>
  <div class="sectionContainer">
    <div class="calculation-window-wrapper">
      <div class="input0" v-show="showInput == 1">
        <h2 class="subheader">1. Name</h2>
        <div class="centeredSpaceSection">
          <p class="text text--label">Projectname</p>
          <input
            class="textInputBig"
            name="projectName"
            id="projectName"
            type="text"
            placeholder="projectName"
            v-model="projectName"
          />
        </div>
      </div>
      <div class="input1" v-show="showInput == 2">
        <h2 class="subheader">2. Map</h2>
        <Map @apply="applyPosition"></Map>
      </div>
      <div class="input2" v-show="showInput == 3">
        <h2 class="subheader">3. Turbine</h2>
        <div class="centeredSpaceSection">
          <p class="text text--label">Select turbine:</p>
          <div class="select">
            <select v-model="turbine">
              <option selected disabled value="null">--Select--</option>
              <option value="0">Enercon E-53</option>
              <option value="1">Enercon E-82 E2 2.3</option>
              <option value="2">Enercon E-101 E2 3.05</option>
              <option value="3">Enercon E-103 EP2 2.35</option>
              <option value="4">Enercon E-138</option>
              <option value="5">Enercon E-160</option>
              <option value="6">LTW 80 1,8 MW</option>
              <option value="7">LTW 90 1 MW</option>
              <option value="8">LTW 90 2 MW</option>
              <option value="9">Nordex N117 2.4</option>
              <option value="10">Vensys VE112 2.4</option>
              <option value="11">Vestas V126 3.3 MW</option>
            </select>
            <div class="select_arrow"></div>
            <div class="tooltipPosition">
              <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
                i
              </button>
            </div>
          </div>
          <p class="text text--label">Hub Height:</p>
          <div class="select">
            <select v-model="hubHeight">
              <option selected disabled value="null">--Select--</option>
              <option :value="e" v-for="e in hubheights[turbine]" :key="e">
                {{ e }}
              </option>
            </select>
            <div class="select_arrow"></div>
            <div class="tooltipPosition">
              <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
                i
              </button>
            </div>
          </div>
          <p class="text text--label">Forest height</p>
          <input
            class="textInputBig"
            name="forestHeight"
            id="forestHeight"
            type="number"
            placeholder="50"
            v-model="forestHeight"
            min="0"
          />
          <div class="tooltipPosition">
            <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
              i
            </button>
          </div>
        </div>
      </div>
      <div class="input3" v-show="showInput == 4">
        <h2 class="subheader">4. Financials</h2>
        <div class="centeredSpaceSection">
          <p class="text text--label">P-Scenario:</p>
          <div class="select">
            <select v-model="pSzenario">
              <option selected disabled value="null">--Select--</option>
              <option value="0">P50</option>
              <option value="1">P75</option>
              <option value="2">P90</option>
            </select>
            <div class="select_arrow"></div>
            <div class="tooltipPosition">
              <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
                i
              </button>
            </div>
          </div>
          <p class="text text--label">Equity:</p>
          <div class="select">
            <select v-model="equity">
              <option selected disabled value="null">--Select--</option>
              <option v-for="i in 100" :key="i" :value="i / 100">
                {{ i }}%
              </option>
            </select>
            <div class="select_arrow"></div>
            <div class="tooltipPosition">
              <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
                i
              </button>
            </div>
          </div>
          <p class="text text--label">Interest rate:</p>
          <div class="select">
            <select v-model="interest">
              <option selected disabled value="null">--Select--</option>
              <option v-for="i in 60" :key="i" :value="i / 1000">
                {{ i / 10 }}%
              </option>
            </select>
            <div class="select_arrow"></div>
            <div class="tooltipPosition">
              <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
                i
              </button>
            </div>
            <p class="text text--label">Hebesatz</p>
            <input
              class="textInputBig"
              name="hebeSatz"
              id="hebeSatz"
              type="number"
              placeholder="350"
              v-model="hebeSatz"
              min="0"
            />
            <div class="tooltipPosition">
              <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
                i
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="input4" v-show="showInput == 5">
        <h2 class="subheader">5. Power</h2>
        <div class="centeredSpaceSection">
          <p class="text text--label">CAPEX p.KW:</p>
          <input
            class="textInputBig"
            name="capex"
            id="capex"
            type="number"
            placeholder="1300"
            v-model="capex"
            min="0"
          />
          <div class="tooltipPosition">
            <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
              i
            </button>
          </div>
          <p class="text text--label">Feed-In tarif (in Cent):</p>
          <input
            class="textInputBig"
            name="feedInTarif"
            id="feedInTarif"
            type="number"
            placeholder="6.2"
            v-model="feedIn"
          />
          <div class="tooltipPosition">
            <button class="tooltipInfo" data-tooltip="I’m the tooltip text.">
              i
            </button>
          </div>
        </div>
      </div>
      <div class="progressArea">
        <div class="progressBar">
          <div
            class="progressDot"
            :class="{ 'progressDot--progressDotActive': showInput == '1' }"
            @click="showInput = 1"
          ></div>
          <div
            class="progressDot"
            :class="{ 'progressDot--progressDotActive': showInput == '2' }"
            @click="showInput = 2"
          ></div>
          <div
            class="progressDot"
            :class="{ 'progressDot--progressDotActive': showInput == '3' }"
            @click="showInput = 3"
          ></div>
          <div
            class="progressDot"
            :class="{ 'progressDot--progressDotActive': showInput == '4' }"
            @click="showInput = 4"
          ></div>
          <div
            class="progressDot"
            :class="{ 'progressDot--progressDotActive': showInput == '5' }"
            @click="showInput = 5"
          ></div>
        </div>
        <div class="buttonArea">
          <button class="button" @click="backInput" v-show="showInput > 1">
            back
          </button>
          <button class="button" @click="nextInput">next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import Map from "@/components/Map.vue";
import { useStore } from "vuex";
import router from "@/router";
const url = process.env.VUE_APP_ENV_SERVER || "";
import axios from "axios";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

export default {
  name: "Calculation",
  components: { Map },
  setup() {
    const store = useStore();
    const showInput = ref(1);
    const projectName = ref(null);
    const feedIn = ref();
    const capex = ref();
    const interest = ref(null);
    const equity = ref(null);
    const setPosition = ref(null);
    const setAmount = ref(null);
    const pSzenario = ref(null);
    const turbine = ref(null);
    const forestHeight = ref(null);
    const hubHeight = ref(null);
    const hebeSatz = ref(null);
    const hubheights = ref([
      ["50", "60", "73"],
      ["78", "85", "98", "108", "138"],
      ["74"],
      ["98", "138"],
      ["81", "111", "131", "160"],
      ["120", "166"],
      ["49", "60", "65", "80"],
      ["65", "80", "90", "97.50", "105", "130"],
      ["50", "65", "80", "97.5"],
      ["91", "120", "141"],
      ["93.5", "143.5"],
      ["117", "137"],
    ]);
    function checkProjectName() {
      const re = /^([\w.-][\s]*)+$/;
      return !re.test(projectName.value);
    }
    function checkNumberWithDigit(number) {
      const re = /^\d*(\.\d*)?$/;
      return !re.test(number);
    }
    function backInput() {
      showInput.value = showInput.value - 1;
    }
    function nextInput() {
      if (showInput.value < 5) {
        showInput.value = showInput.value + 1;
      } else {
        if (
          projectName.value &&
          feedIn.value &&
          capex.value &&
          interest.value &&
          equity.value &&
          setPosition.value &&
          pSzenario.value &&
          turbine.value &&
          hubHeight.value &&
          hebeSatz.value &&
          forestHeight.value
        ) {
          if (checkProjectName()) {
            Swal.fire({
              title: "Error!",
              text: "Please do not use special characters in your project name",
              icon: "error",
              confirmButtonText: "Okay",
            });
          } else if (checkNumberWithDigit(feedIn.value)) {
            Swal.fire({
              title: "Error!",
              text: "Please input valid feed in",
              icon: "error",
              confirmButtonText: "Okay",
            });
          } else if (checkNumberWithDigit(hebeSatz.value)) {
            Swal.fire({
              title: "Error!",
              text: "Please input valid Hebesatz",
              icon: "error",
              confirmButtonText: "Okay",
            });
          } else if (checkNumberWithDigit(forestHeight.value)) {
            Swal.fire({
              title: "Error!",
              text: "Please input valid forest height",
              icon: "error",
              confirmButtonText: "Okay",
            });
          } else if (checkNumberWithDigit(capex.value)) {
            Swal.fire({
              title: "Error!",
              text: "Please input valid CAPEX",
              icon: "error",
              confirmButtonText: "Okay",
            });
          } else if (
            parseFloat(forestHeight.value) > parseFloat(hubHeight.value)
          ) {
            Swal.fire({
              title: "Error!",
              text: "Seems like your forest height is higher then hub height. Are you sure about that?",
              icon: "error",
              confirmButtonText: "Okay",
            });
          } else {
            store.dispatch("setShowLoadingScreen", true);
            axios
              .get(url + "/getCalculation", {
                params: {
                  lat: setPosition.value.lat,
                  lng: setPosition.value.lng,
                  projectName: projectName.value.trim(),
                  feedIn: feedIn.value / 100,
                  capex: capex.value,
                  interest: interest.value,
                  equity: equity.value,
                  pSzenario: pSzenario.value,
                  turbine: turbine.value,
                  machinesAcrossWindDirection: setAmount.value,
                  forestHeight: forestHeight.value,
                  hubHeight: hubHeight.value,
                  hebeSatz: hebeSatz.value,
                },
              })
              .then((response) => response.data)
              .then((serverData) => {
                console.log(serverData);
                const decoded = jwt_decode(serverData.token);
                store.dispatch("setCalculation", decoded.calculation);
                store.dispatch("setToken", serverData.token);
                store.dispatch("showSideBarElements");
                router.push("/tabs/Dashboard");
                store.dispatch("setShowLoadingScreen", false);
              });
          }
        } else {
          Swal.fire({
            title: "Error!",
            text: "Missing inputs",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      }
    }
    function applyPosition(position, amount) {
      setPosition.value = position;
      setAmount.value = amount;
      console.log(position);
      console.log(amount);
    }
    return {
      backInput,
      showInput,
      nextInput,
      feedIn,
      capex,
      interest,
      equity,
      setPosition,
      setAmount,
      applyPosition,
      pSzenario,
      turbine,
      forestHeight,
      hubheights,
      hebeSatz,
      hubHeight,
      projectName,
    };
  },
};
</script>

<style lang="scss" scoped>
.tooltipPosition {
  position: relative;
  top: -55px;
  right: -290px;
  display: inline-block;
}
</style>
